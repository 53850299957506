import React from 'react';
import { navigate } from 'gatsby-link'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline'

function encode(data: any) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

enum AlertStatus {
    Success = 1,
    Exclamation = 2
};

interface AlertProps {
    show: boolean;
    status?: AlertStatus;
    title: string;
    message: string;
    onHandleClose: () => void;
}

function Alert({ show, status, title, message, onHandleClose }: AlertProps) {

    return <div className={(show ? "" : "hidden") + " mb-8 transition"}>
        <div className="flex flex-col sm:flex-row sm:items-center bg-white shadow-md rounded-lg py-5 pl-6 pr-8 sm:pr-6">
            <div className="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
                {status === AlertStatus.Success && <CheckCircleIcon className="w-6 sm:w-5 h-6 sm:h-5 text-green-500" />}
                {status === AlertStatus.Exclamation && <ExclamationCircleIcon className="w-6 sm:w-5 h-6 sm:h-5 text-yellow-500" />}

                <div className="text-sm font-medium ml-3">{title}</div>
            </div>
            <div className="text-sm tracking-wide text-gray-500 mt-4 sm:mt-0 sm:ml-4">{message}</div>
            <div className="absolute sm:relative sm:top-auto sm:right-auto ml-auto right-4 top-4 text-gray-400 hover:text-gray-800 cursor-pointer">
                <svg onClick={() => onHandleClose()} className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
            </div>
        </div>
    </div>;
}

function Contact(props: any) {

    const [formState, setFormState] = React.useState({})
    const [alertShow, setAlertShow] = React.useState(false);
    const [alertStatus, setAlertStatus] = React.useState<AlertStatus>(AlertStatus.Success);
    const [alertTitle, setAlertTitle] = React.useState("");
    const [alertMessage, setAlertMessage] = React.useState("");

    const handleChange = (e: any) => {

        var newState = { ...formState, [e.target.name]: e.target.value };

        setFormState(newState);
    }

    const handleSubmit = (e: any) => {

        e.preventDefault();

        const form = e.target;

        fetch(props.location.pathname, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...formState,
            }),
        })
            .then((a) => {
                if (a.status === 200) {
                    form.reset();

                    navigate(form.getAttribute('action'));

                    setAlertStatus(AlertStatus.Success);
                    setAlertShow(true);

                    setAlertTitle("Message Sent");
                    setAlertMessage("Thank you for your message!");

                } else {

                    setAlertStatus(AlertStatus.Exclamation);
                    setAlertShow(true);

                    setAlertTitle("Error Sending Message");
                    setAlertMessage("An error occured while sending your message.");
                }
            })
            .catch((error: any) => {

                setAlertStatus(AlertStatus.Exclamation);
                setAlertShow(true);

                setAlertTitle("Error Sending Message");
                setAlertMessage("An error occured while sending your message.");
            });
    }

    return (
        <div className="container mx-auto py-24 px-4">

            <Alert show={alertShow} status={alertStatus} title={alertTitle} message={alertMessage} onHandleClose={() => setAlertShow(false)} />

            {/* 
            <button className="base-button" onClick={() => setAlertShow(!alertShow)}>Show / Hide</button>
            <button className="base-button" onClick={() => setAlertStatus(alertStatus === AlertStatus.Success ? AlertStatus.Exclamation : AlertStatus.Success)}>Test</button>
            */}

            <form
                name="contact"
                method="POST"
                action="/contact/"
                data-netlify="true"
                // data-netlify-recaptcha="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
            >
                <input type="hidden" name="form-name" value="contact" />

                <h1 id="contact" className="w-full text-center pb-8">Get in Touch</h1>

                <p hidden>
                    <label htmlFor="bot-field">Don't fill this out:</label>
                    <input name="bot-field" onChange={handleChange} />
                </p>

                <div className="mb-6">
                    <label htmlFor="name" className="mb-3 block text-gray-700">Name:</label>
                    <input type="text" name="name" id="name" className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full" onChange={handleChange} required />
                </div>

                <div className="mb-6">
                    <label htmlFor="email" className="mb-3 block text-gray-700">Email address:</label>
                    <input type="email" name="email" id="email" className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full" onChange={handleChange} required />
                </div>

                <div className="mb-6">
                    <label htmlFor="subject" className="mb-3 block text-gray-700">Subject:</label>
                    <input type="subject" name="subject" id="subject" className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full" onChange={handleChange} required />
                </div>

                <div className="mb-6">
                    <label htmlFor="message" className="mb-3 block text-gray-700">Message:</label>
                    <textarea id="message" name="message" className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full" rows={15} onChange={handleChange} required></textarea>
                </div>

                {/* <div className="mb-6">
                        <div data-netlify-recaptcha="true"></div>
                    </div> */}

                <button type="submit" className="bg-blue-300 rounded-md text-white py-3 px-12 w-full">
                    Send E-Mail
                </button>

            </form>

        </div>
    );
}

export default Contact;