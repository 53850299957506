import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Contact from "../components/contact"

const ContactPage = () => (
  <Layout>
    <Seo title="Get in Touch" />

    <Contact />
  </Layout>
)

export default ContactPage
